import { isMobile } from '../utils/index'
export default {
  address: '新北市三重區光復路一段61巷31弄1號',
  googleSrc:
    'https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3613.929333123806!2d121.46859961500695!3d25.070384183955255!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3442a92cda243cb9%3A0x4e1759be8296a7b6!2z552_5pqY5rC05ry-!5e0!3m2!1szh-TW!2stw!4v1605583844068!5m2!1szh-TW!2stw',
  googleLink: 'https://goo.gl/maps/nC8eknL4TccWz9yE7',
  phone: '02-2278-2685',
  fbLink: 'https://www.facebook.com/AQUAVITACITY',
  fbMessage: 'https://m.me/AQUAVITACITY',
  caseName: '睿暘水漾',
  indigatorLength: 10,

  houseInfos: [
    ['投資興建', '睿暘開發建設',],
    ['建築設計', '張文正建築師事務所',],
    ['企劃銷售', '海沃創意行銷',],
    ['基地面積', '約338.6坪',],
    ['樓層規劃', '12F/B2、共49戶',],
    ['樓層高度', '1樓6米；2~12樓3.6米',],
    ['公設比例', '33.82%',],
    ['規劃坪數', '18~28坪',],
    ['基地地址', '新北市三重區疏洪東路二段',],
    ['接待中心', '新北市三重區光復路一段61巷31弄1號',],
  ],

  gtmCode: ['WG7P8LC'], // 可放置多個
  recaptcha_site_key_v2: '6Lep-78UAAAAAMaZLtddpvpixEb8cqu7v7758gLz',
  recaptcha_site_key: '6Lck-L8UAAAAABxfvNA1NJuBrdl3iLFc3GkudC8s', // recaptcha v3
  recaptcha_user_token: '6Lck-L8UAAAAAIcvenwGDl8_Q1tKbrEYsKuriePa',
  order: {
    title: '立即預約',
    subTitle: ''
  }
}
