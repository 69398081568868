export default [
  { name: '睿暘水漾', imgSrc: '', subTitle: '', section: 'section1', svgSrc: '', offset: -0 },
  { name: '漾豐景', imgSrc: '', subTitle: '', section: 'section3', svgSrc: '', offset: -0 },
  { name: '漾生活', imgSrc: '', subTitle: '', section: 'section4', svgSrc: '', mobileOffset: 0 },
  { name: '漾建築', imgSrc: '', subTitle: '', section: 'section6', svgSrc: '', mobileOffset: 0 },
  { name: '漾家居', imgSrc: '', subTitle: '', section: 'section8', svgSrc: '', mobileOffset: 0 },
  { name: '漾團隊', imgSrc: '', subTitle: '', section: 'section10', svgSrc: '', mobileOffset: 0 },
  { name: '預約參觀', imgSrc: '', subTitle: '', section: 'contact', svgSrc: '', mobileOffset: -0 },
  { name: '建案資訊', imgSrc: '', subTitle: '', section: 'house-info', svgSrc: '', mobileOffset: -0 },
]
